var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "PRODUCTION",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api-aou.kodefuse.com/v1",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        },
        DEVELOPMENT: {
            API_URL: "APP_API_URL",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        },
        STAGING: {
            API_URL: "https://api-aou-qa.kodefuse.com/v1",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        },
        PRODUCTION: {
            API_URL: "https://api.ageofuncertaintycoaching.com/v1",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;
